import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
import Adsearch from '@/components/Adsearch'
const Index = {
  name: 'licenseWarning',
  components: {
    PageHeader,
    Adsearch
  },
  mixins: [tableMixin],
  data() {
    return {
      activeName: '1',
      isShow: false,
      searchWords: {
        keywords: '',
        WaringDays: '30',
        InvCode: '',
        InvName: '',
        RegisterNo: '',
        BrandName: '',
        DateLine: [],
        PageIndex: 1,
        PageSize: 20,
        ExpireType: '', //  -1 全部 2：即将过期 3 已过期
        type: 1 // 请求类型  1： 注册证 2：客户 3：供应商 4：我的证件
      },
      adSearchForm: [
        { name: '物料编号', value: 'InvCode' },
        { name: '物料名称', value: 'InvName' },
        { name: '注册证号', value: 'RegisterNo' },
        { name: '品牌', value: 'BrandName' },
        {
          name: '是否过期',
          check: true,
          checkBox: [{
            name: '即将过期'
          }, {
            name: '已过期'
          }],
          value: 'DateLine'
        }
      ],
      nums: {
        ZCZ: '',
        KH: '',
        GYS: '',
        MINE: ''
      }
    }
  },
  mounted() {
    this.GetCertificateWarningNum()
  },
  methods: {
    getType(type) {
      return type <= 0 ? '已过期' : '即将过期'
    },
    searchAd() {
      if (this.searchWords.DateLine.length === 0) {
        this.searchWords.ExpireType = ''
      }
      if (this.searchWords.DateLine.length === 1 && this.searchWords.DateLine[0] === '即将过期') {
        this.searchWords.ExpireType = '2'
      }
      if (this.searchWords.DateLine.length === 1 && this.searchWords.DateLine[0] === '已过期 ') {
        this.searchWords.ExpireType = '3'
      }
      if (this.searchWords.DateLine.length === 2) {
        this.searchWords.ExpireType = '-1'
      }
      console.log(this.searchWords)
      this.setKeysData(this.searchWords, this.adSearchForm) // 展示搜索条件
      this.getTableDataAsync()
      this.isShow = false
    },
    // 预警数量
    GetCertificateWarningNum() {
      this.$api.GetCertificateWarningNum(this.searchWords).then(res => {
        console.log(res)
        if (res.Message) {
          this.nums = res.Message
        }
      })
    },
    downLoad(path) {
      console.log(path)
      if (!path) return this.$message.error('没有证照')
      this.$api.GetFirstCampFiles({
        FileURL: `${this.b2bstaticUrl} ${path}`
      }).then(res => {
        console.log(res)
        this.$minCommon.downloadFile(res.Message, '.' + path.split('.')[1])
      })
    },
    activeNameClick(a) {
      console.log(a.name)
      this.searchWords.type = a.name
      this.currentPage = 1
      this.getTableDataAsync()
    },
    view_page(row, flag) {
      console.log(row)
      if (flag) {
        // 跳到产品
        this.$router.push({
          name: 'uploadProductFiles1',
          params: { type: 2, idx: row.IDX }
        })
        return
      }
      // this.$router.push({
      //   name: 'Edit1',
      //   params: { IDX: row.IDX, active: 1, TYPE: '1' }
      // })
      this.$router.push({
        name: 'Edit1',
        params: {
          type: 1,
          IDX: row.cusLstIDX
        }
      })
    },
    async getTableDataAsync(page, pageSize) {
      // api/QueryStockMaterialList
      this.searchWords.PageIndex = page || 1
      this.searchWords.PageSize = this.tablePageSize
      console.log(' this.searchWords', this.searchWords)
      const response = await this.$api.GetCertificateWaringList(this.searchWords)
      this.tableData = Array.isArray(response.Message) ? response.Message : []
      this.tableTotalSize = response.Total
      console.log(response)
      return response
    }
  }
}
export default Index
