<template>
  <div class="license-warning">
    <div class="listHeader SecondNav">
      <el-tabs v-model="activeName" type="card" @tab-click="activeNameClick">
        <el-tab-pane name="1">
          <span slot="label">注册证<el-badge class="DataNub" :value="nums.ZCZ" /></span>
        </el-tab-pane>
        <el-tab-pane name="2">
          <span slot="label">客户证件<el-badge class="DataNub" :value="nums.KH" /></span>
        </el-tab-pane>
        <el-tab-pane name="3">
          <span slot="label">供应商证件<el-badge class="DataNub" :value="nums.GYS" /></span>
        </el-tab-pane>
        <el-tab-pane name="4">
          <span slot="label">我的证件<el-badge class="DataNub" :value="nums.MINE" /></span>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="searchView  flex a-center m-bottom-10 p-lr-20 j-between">
      <div class="flex">
        <!--        <div class="out flex a-center m-right-10 cursor ModelBttton-white-30">-->
        <!--          <i class="el-icon-upload2 f15  p-right-10 " />   导出-->
        <!--        </div>-->
        <!-- <div class="out flex a-center m-right-10 cursor ModelBttton-white-30" @click="() => $refs.FieldManagement.openSetFieldManagement()">
          <i class="el-icon-setting f15 p-right-5" />   字段管理
        </div> -->
        <div class="out flex a-center m-right-10 cursor ModelBttton-white-30">
          <i class="el-icon-setting f15 p-right-5" />   有效期30天
        </div>
      </div>
      <div class="flex" style="flex:1">
        <el-input
          v-model="searchWords.keywords"
          size="medium"
          :placeholder="activeName === '1' ? '可通过物料号、物料名称、注册证号搜索' : activeName === '2' ? '可通过公司名称、公司编号、企业营业执照名称搜索': activeName === '3'? '可通过公司名称、公司编号、企业营业执照名称搜索' : ''"
          class="rule-input-edit"
        >
          <template v-if="activeName === '1' " slot="prepend">
            <div class="cursor" @click="() => isShow = true">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <template v-else slot="prepend">
            <i class="el-icon-search" />
          </template>
          <div slot="append" class="cursor" @click="searchGetTableDataAsync">搜  索</div>
        </el-input>
      </div>
    </div>
    <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,searchWords,adSearchForm)" />

    <div class="m-lr-20">
      <!-- 注册证 -->
      <div v-show="activeName === '1'">
        <el-table
          ref="multipleTable"
          v-loading="tableLoading"
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="tableData"
          tooltip-effect="dark"
          show-header
          height="calc(100vh - 325px)"
          row-key="id"
          border
        >

          <af-table-column
            fixed="left"
            type="selection"
            width="40"
            align="center"
          />
          <af-table-column prop="InvCode" label="物料编码" align="left" show-overflow-tooltip />
          <af-table-column prop="InvName" label="物料名称" align="left" show-overflow-tooltip />
          <af-table-column prop="BrandName" label="品牌" align="left" show-overflow-tooltip />
          <af-table-column prop="Specification" label="规格/型号" align="left" show-overflow-tooltip />
          <el-table-column prop="MStockUnitN" label="单位" align="left" show-overflow-tooltip />
          <af-table-column prop="MaterialTypeName" label="物料类型" align="left" show-overflow-tooltip />
          <af-table-column prop="RegisterNo" label="注册证号/批准文号/备案凭证号" align="left" show-overflow-tooltip />
          <af-table-column prop="RegisterEnd" fixed="right" label="有效期至" align="left" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $minCommon.formatDate(new Date(scope.row.RegisterEnd), "yyyy-MM-dd") }}
            </template>
          </af-table-column>
          <af-table-column prop="OffDays" label="状态" fixed="right" align="left" show-overflow-tooltip>
            <template slot-scope="scope">
              <span :class="scope.row.OffDays <= 0 ? 'alerdy' : 'no_alerdy' ">{{ getType(scope.row.OffDays) }}</span>
            </template>
          </af-table-column>
          <el-table-column
            label-position="mid"
            width="50"
            align="center"
            prop="set"
            label="操作"
            fixed="right"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="hover"
                :width="50"
                popper-class="test_pop_view"
              >
                <div class="DropDownList">
                  <div class="OperationButton" @click="view_page(scope.row,true)">
                    <!-- @click="Turn('/product/productEdit?idx='+scope.row.IDX)"  slot-scope="scope"-->
                    <el-link :underline="false"><i class="el-icon-edit p-right-10" />查看</el-link>
                  </div>
                  <div class="OperationButton" @click="downLoad(scope.row.CertificatePath)">
                    <el-link :underline="false"><i class="el-icon-download p-right-10" />下载</el-link>
                  </div>
                </div>
                <i slot="reference" class="el-icon-more" />
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 其他证照 -->
      <div v-show="activeName !== '1'">
        <el-table
          ref="multipleTable"
          v-loading="tableLoading"
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="tableData"
          tooltip-effect="dark"
          show-header
          :height="tableHeight"
          row-key="id"
          border
        >
          <af-table-column
            fixed="left"
            type="selection"
            width="55"
          />
          <af-table-column v-if="activeName !== '4'" prop="CompanyNo" :label="activeName === '2' ? '客户编码' :activeName === '3' ? '供应商编码' :'' " align="center" show-overflow-tooltip />
          <af-table-column v-if="activeName !== '4'" prop="KS_CompanyName" :label="activeName === '2' ? '客户名称' :activeName === '3' ? '供应商名称' :'' " align="center" show-overflow-tooltip />
          <af-table-column prop="TypeString" label="证照类型" align="center" show-overflow-tooltip />
          <af-table-column prop="ValidToString" label="到期日期" align="center" show-overflow-tooltip />
          <el-table-column prop="OffDays" label="状态" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span :class="scope.row.OffDays <= 0 ? 'alerdy' : 'no_alerdy' ">{{ getType(scope.row.OffDays) }}</span>
            </template>
          </el-table-column>
          <!-- <template v-for="(item,index) in tableListJson.licenseWarning">
            <af-table-column
              v-if="item.isChecked"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <template v-if="item.isChecked && item.prop === 'OffDays'" slot-scope="scope">
                <span :class="scope.row.OffDays ? 'alerdy' : 'no_alerdy' ">{{ getType(scope.row.OffDays) }}</span>
              </template>

              <template v-if="item.isChecked && item.prop !== 'OffDays'" slot-scope="scope">
                {{ scope.row[item.prop] }}
              </template>
            </af-table-column>
          </template> -->
          <!-- <af-table-column v-if="activeName !== '4'" prop="CompanyNo" :label="activeName === '2' ? '客户编码' :activeName === '3' ? '供应商编码' :'' " align="center" show-overflow-tooltip />
          <af-table-column v-if="activeName !== '4'" prop="KS_CompanyName" :label="activeName === '2' ? '客户名称' :activeName === '3' ? '供应商名称' :'' " align="center" show-overflow-tooltip /> -->
          <el-table-column
            label-position="mid"
            align="center"
            prop="set"
            label="操作"
            fixed="right"
            show-overflow-tooltip
            width="50"
          >
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="hover"
                :width="50"

                popper-class="test_pop_view"
              >
                <div class="DropDownList">
                  <!-- @click="Turn('/product/productEdit?idx='+scope.row.IDX)"  slot-scope="scope"-->
                  <div class="OperationButton"><el-link :underline="false" @click="view_page(scope.row)"><i class="el-icon-edit p-right-10" />查看</el-link></div>
                  <div class="OperationButton"><el-link @click="downLoad(scope.row.PhotoUrl )"><i class="el-icon-download p-right-10" />下载</el-link></div>
                </div>
                <i slot="reference" class="el-icon-more" />
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :current-page.sync="currentPage"
          :background="tableBackground"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <Adsearch :form="searchWords" :ad-search-form="adSearchForm" :show="isShow" @cancel="(e) => isShow = e" @searchAd="searchAd" />
    <!-- 字段搜索 -->
    <FieldManagement ref="FieldManagement" action-name="GetCertificateWaringList" :list="tableListJson.licenseWarning" name="licenseWarning" :change-list-table="changeListTable" />
  </div>
</template>
<script>
import Index from '@/minxin/license-warning/index'

export default Index
</script>
<style lang="scss" scoped>
@import '@/style/search.scss';
.alerdy {
  color:  red;
}
.no_alerdy {
  color:  green;
}
</style>
